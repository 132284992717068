<template>
  <div
    class="w-100"
  >
    <AsyncList
      :is-all-items-loaded="isAllItemsLoaded || error"
      without-loader
      class="h-100"
      @load-more-items="promocodesFetch(false)"
    >
      <b-container fluid="xl pt-4">
        <div class="mb-3 d-flex align-items-center">
          <div class="h3 mb-0 title">
            Промокоды
          </div>

          <div class="artyom-title">
            design by Artyom Rostomyan
          </div>
        </div>

        <PromocodesSearchPanel
          v-model="query"
          @search="promocodesFetch(true)"
        >
          <template
            v-if="checkFeatureAccess({ name: 'Новый промокод', url: '/promocodes' })"
            #search-right
          >
            <b-button
              class="ml-2"
              variant="primary text-nowrap"
              :type="$const.PRIMARY_BUTTON"
              @click="openPromocodeModal(null)"
            >
              <b-icon
                icon="plus"
                aria-hidden="true"
              />
              Новый промокод
            </b-button>
          </template>

          <template #filters>
            <PromocodesSearchFilters
              v-model="filters"
              @search="promocodesFetch(true)"
            />
          </template>
        </PromocodesSearchPanel>

        <div
          v-if="promocodes.length"

          class="mt-4"
        >
          <PromocodeItem
            v-for="promocode in promocodes"
            :key="promocode.id"
            :promocode-data="promocode"
            class="mt-2"
            @edit="openPromocodeModal(promocode.id)"
            @remove="openDeleteModal(promocode.id)"
          />
        </div>

        <div
          v-if="loading"
          style="transform: translate(0, -15px)"
          class="mt-4"
        >
          <b-card
            v-for="item in 14"
            :key="item"
            class="mt-2 rounded shadow-lg border-0"
          >
            <b-skeleton
              animation="wave"
              width="7%"
            />
            <b-skeleton
              animation="wave"
              width="45%"
            />
            <b-skeleton
              animation="wave"
              width="70%"
            />
            <b-skeleton
              animation="wave"
              width="60%"
            />
            <b-skeleton
              animation="wave"
              width="55%"
            />
            <b-skeleton
              animation="wave"
              width="70%"
            />
          </b-card>
        </div>

        <div
          v-if="accident"
          class="mt-3 p-3 bg-white shadow-sm rounded"
        >
          {{ accident }}
        </div>
      </b-container>
    </AsyncList>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';

import AsyncList from '@/components/AsyncList';
import PromocodesSearchPanel from '@/components/Promocodes/PromocodesSearchPanel';
import PromocodesSearchFilters from '@/components/Promocodes/PromocodesSearchFilters';
import PromocodeItem from '@/components/Promocodes/PromocodeItem';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'Promocodes',
  components: {
    AsyncList,
    PromocodesSearchPanel,
    PromocodesSearchFilters,
    PromocodeItem,
  },
  mixins: [mixinRoles],

  data: () => ({
    partyOn: false,
    loading: false,
    query: '',
    filters: {
      isActual: true,
      attribute: 3,
    },
    error: false,
  }),
  computed: {
    promocodes() {
      return this.$store.getters[this.$types.PROMOCODES_GET];
    },
    isAllItemsLoaded() {
      return this.$store.getters[this.$types.PROMOCODES_IS_ALL_ITEMS_LOADED_GET];
    },
    accident() {
      if (this.error) {
        return 'Произошла ошибка';
      }
      if (this.isAllItemsLoaded && !this.promocodes.length) {
        return 'Ничего не найдено';
      }

      return false;
    },
  },
  created() {
    this.promocodesFetch(true);
  },

  methods: {
    async promocodesFetch(clear) {
      if (this.loading) return;
      this.loading = true;

      try {
        await this.$store.dispatch(this.$types.PROMOCODES_FETCH, {
          clear,
          query: this.query,
          isActual: this.filters.isActual,
          attribute: this.filters.attribute,
        });
      } catch (e) {
        console.error(e);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    openPromocodeModal(id) {
      uiService.showModal(MODALS.PROMOCODE_EDIT_MODAL, {
        name: 'PromocodeEditModal',
        props: {
          promocodeId: id || null,
          title: id ? 'Редактирование промокода' : 'Добавление промокода',
        },
        actions: {
          search: () => {
            this.promocodesFetch(true);
          },
        },
      });
    },
    async deletePromocode(id) {
      try {
        await this.$store.dispatch(this.$types.PROMOCODE_DELETE, id);

        this.$store.commit(this.$types.PROMOCODE_REMOVE_BY_ID, id);
        this.$store.dispatch(this.$types.PROMOCODES_FETCH, true);
      } catch (e) {
        console.error(e);
      }
    },
    openDeleteModal(id) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            title: 'Удаление промокода',
            cb: this.deletePromocode,
            cbArgs: [id],
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>

@keyframes shadow-animation {
  0% {
    text-shadow: 0 0 5px #000;
  }
  50% {
    text-shadow: 0 0 10px #000;
  }
  100% {
    text-shadow: 0 0 5px #000;
  }
}

.title {
  &:hover {
    & + .artyom-title {
      transform: translate(0);

      &::before {
        opacity: 1;
      }
    }
  }
}

.artyom-title {
  position: relative;
  margin-left: 20px;
  font-size: 14px;
  transform: translate(-100px, -100px);
  transition: transform 1s;
  animation: shadow-animation 1s infinite;

  &::before {
    content: "";
    position: absolute;
    left: 50px;
    bottom: -70px;
    width: 50px;
    height: 200px;
    background-image: radial-gradient(circle, rgba(255, 255, 0, 0.8) 10%, transparent 70%);
    transform: rotate(-45deg);
    opacity: 0;
    transition: 1s all 1s;
    z-index: -1;
  }
}
</style>
